<template>
    <div class="pages-container">
        <h1 class="title">{{ $t('pages.pages') }}</h1>
        <CRButton class="mt-25" @click="toCreatePage"> {{ $t('buttons.newPage') }} </CRButton>
        <div v-if="pages.length === 0" class="empty__wrapper">
            <h1>{{ $t('pages.noPagesAdded') }}</h1>
        </div>
        <div class="card__wrapper" v-else>
            <PageCard v-for="page in pages" :page="page" @delete="onDelete" @click="onPageClick(page.id)" :key="page.id"/>
        </div>
        <Pagination
            v-if="pages.length"
            :current-page="meta.currentPage"
            :page-count="meta.pageCount"
            :total-count="meta.totalCount"
            :per-page="meta.perPage"
            @pageNum="changePage"
        />
    </div>
</template>
<script>
import CRButton from '@/components/Common/Buttons/CRButton.vue';
import PageCard from '@/components/Admin/Page/PageCard.vue';
import Pagination from '@/components/Common/Pagination/Pagination.vue';
import { mapGetters, mapActions } from 'vuex';
export default {
    components: {
        CRButton,
        PageCard,
        Pagination,
    },
    data() {
        return {
            
        }
    },
    computed: {
        ...mapGetters('page', ['pages', 'meta']),
    },
    methods: {
        ...mapActions('page', ['getAllPages', 'destroyPage']),
        toCreatePage() {
            this.$router.push({ name: 'createPage' });
        },
        onDelete(id) {
            this.destroyPage(id);
        },
        onPageClick(id) {
            this.$router.push({
                name: 'updatePage',
                params: { page_id: id },
            });
        },
        async changePage(pageNum) {
            try {
                await this.getAllPages({ page: pageNum });
            } catch (error) {
                this.$filters.toast(error.message);
            }
        }
    },
    async mounted() {
        try {
            await this.getAllPages();
        } catch (error) {
            this.$filters.toast(error.message);
        }
    }
}
</script>
<style lang="scss" scoped>
    .pages-container {
        padding-bottom: 30px;

        .empty__wrapper {
            @include column-align-center-justify-center;

            min-height: 300px;
            width: 100%;
        }

        .card__wrapper {
            margin-top: 14px;

            .card {
                margin-bottom: 25px;

                &:hover {
                    transform: scale(1);
                }
            }
        }
    }
</style>