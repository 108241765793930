<template>
    <div>
        <div v-if="perPage && totalCount" class="total">
            <p>{{ perPage }} {{ $t('common.outOf') }} {{ totalCount }}</p>
        </div>
        <div class="pagination">
            <Paginate
                :page-count="pageCount"
                :force-page="currentPage"
                :page-range="pageRangeDisplay"
                :margin-pages="marginPages"
                :click-handler="clickCallback"
                prev-text=""
                next-text=""
                container-class="pagination-list"
                page-class="page-item"
                prev-class="prev page-item"
                next-class="next page-item"
                prev-link-class="prev page-link"
                next-link-class="next page-link"
            />
        </div>
    </div>
</template>

<script>
    import Paginate from 'vuejs-paginate-next';

    export default {
        name: 'Pagination',
        components: { Paginate },
        props: {
            currentPage: {
                type: Number,
                default: 1,
            },
            pageCount: {
                type: Number,
                default: 1,
            },
            totalCount: {
                type: Number,
                default: 0,
            },
            perPage: {
                type: Number,
                default: 0,
            },
            pageRange: {
                type: Number,
                default: 3,
            },
            marginPages: {
                type: Number,
                default: 2,
            },
        },
        emits: ['pageNum'],
        inject: ['provideApp'],
        methods: {
            clickCallback(pageNum) {
                this.$emit('pageNum', pageNum);
            },
        },

        computed: {
            pageRangeDisplay() {
                return this.provideApp.$screenInnerWidth <= 768 ? 1 : this.pageRange;
            },
        },
    };
</script>

<style lang="scss">
    .total {
        @include row-align-center-justify-center;

        margin-bottom: 20px;

        p {
            font-style: italic;
            font-weight: 400;
            font-size: $font-14;
        }
    }
    .pagination {
        .pagination-list {
            @include row-align-center-justify-center;

            .page-item {
                position: relative;
                z-index: $index-200;

                .page-link {
                    z-index: $index-200;

                    @include row-align-center-justify-center;

                    border: 2px solid $primary;
                    border-radius: $br-100;

                    margin: 0 4px;

                    width: 41px;
                    height: 41px;

                    font-weight: 700;
                    font-size: $font-14;

                    color: $primary;

                    background: $white;

                    transition: all 0.2s linear;

                    &.next::after,
                    &.prev::after {
                        content: '';
                        display: block;
                        width: 14px;
                        height: 14px;

                        background: url('../../../assets/icons/arrows/arrow-down.svg') center no-repeat;

                        position: absolute;
                        top: 13px;
                        left: 18px;

                        z-index: $index-100;
                    }

                    &.prev::after {
                        transform: rotate(90deg);
                    }

                    &.next::after {
                        transform: rotate(-90deg);
                        left: 17px;
                    }
                }

                &.active .page-link {
                    background: $primary;

                    color: $white;
                }
            }
        }
    }
</style>
